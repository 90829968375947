

const IconLogo  = () => (
  <svg
    width="210mm"
    viewBox="0 0 210 297"
    xmlns="http://www.w3.org/2000/svg"
    
  >
    <path
      style={{
        fill: "#64ffda",
        fillOpacity: 1,
        stroke: "#000",
        strokeWidth: 3.68779,
      }}
      d="M438.264 74.73 72.969 331.424 204.217 758.16l446.408 7.045 144.648-422.38L438.263 74.73zm-.391 44.305 328.398 240.578L633.217 738.65l-410.631-6.322-120.729-382.943 336.016-230.35z"
      transform="scale(.26458)"
    />
    <path
      style={{
        fill: "#64ffda",
        stroke: "#000",
        strokeWidth: 1.254,
        fillOpacity: 1,
      }}
      d="M75.963 74.25h76.535v18.277L98.81 99.952l53.688 7.425v59.971H75.963v-11.994l50.833-7.996-50.833-6.854z"
    />
  </svg>
)
export const GitHubLogo = () => (
  <svg
            xmlns="http://www.w3.org/2000/svg"
            role="img"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-github"
          >
            <title>GitHub</title>
            <path d="M9 19c-5 1.5-5-2.5-7-3m14 6v-3.87a3.37 3.37 0 0 0-.94-2.61c3.14-.35 6.44-1.54 6.44-7A5.44 5.44 0 0 0 20 4.77 5.07 5.07 0 0 0 19.91 1S18.73.65 16 2.48a13.38 13.38 0 0 0-7 0C6.27.65 5.09 1 5.09 1A5.07 5.07 0 0 0 5 4.77a5.44 5.44 0 0 0-1.5 3.78c0 5.42 3.3 6.61 6.44 7A3.37 3.37 0 0 0 9 18.13V22"></path>
          </svg>
)

export default IconLogo